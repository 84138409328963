/*
 * @Author: 罗侠 luoxia@supos.com
 * @Date: 2022-01-20 10:34:37
 * @LastEditors: 罗侠 luoxia@supos.com
 * @LastEditTime: 2023-03-14 13:28:16
 * @FilePath: \apps-ssr-frontend\src\components\Header\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import Head from 'next/head';

const createMarkup = () => {
  return {
    __html: `
      if (window) {
        window.onload = function() {
          // 初始化头尾
            window.lzyheader && window.lzyheader.init();
            window.lzyfooter && window.lzyfooter.init()

          // 百度统计
          var _hmt = _hmt || [];
          (function() {
            var code = window.lzyheader.getbdcode();
            if ( code ) {
              var hm = document.createElement("script");
              hm.src = "https://hm.baidu.com/hm.js?" + code;
              var s = document.getElementsByTagName("script")[0];
              s.parentNode.insertBefore(hm, s);
            }


            changebrowserIconUrl()

            function changebrowserIconUrl() {
              let key = 'customized-portal_config'
              let getLocal = localStorage.getItem(key) || null
              let resolve = JSON.parse(getLocal) || {}
              let link = ''
              if( resolve && resolve.userSideConfig ) {
                document.title = resolve.userSideConfig.browserTitle
                // 动态修改网站图标
                link = resolve.userSideConfig.browserIconUrl;

                let $favicon = document.querySelector('link[rel="icon"]');
                if ($favicon !== null) {
                  $favicon.href = link;
                } else {
                  $favicon = document.createElement("link");
                  $favicon.rel = "icon";
                  $favicon.href = link;
                  document.head.appendChild($favicon);
                }
              }
            }
          })();
        }
      }
    `,
  };
};

const NewHeader = (props) => {
  const {
    appGlobalModel: { getCurrentInfo },
  } = props;

  useEffect(() => {
    getCurrentInfo();
  }, [getCurrentInfo]);

  return (
    <Head>
      <script
        src={`${process.env.REACT_APP_HEADER_ORIGIN}/domainConfig.js`}
      ></script>
      <script
        src={`${process.env.REACT_APP_HEADER_ORIGIN}/lzyheader.umd.min.js`}
      ></script>
      <script
        src={`${process.env.REACT_APP_HEADER_ORIGIN}/lzyfooter.umd.min.js`}
      ></script>
      <script defer dangerouslySetInnerHTML={createMarkup()}></script>
    </Head>
  );
};

export default observer(NewHeader);

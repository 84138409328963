/*
 * @Author: 罗侠 luoxia@supos.com
 * @Date: 2021-07-06 13:19:53
 * @LastEditors: 罗侠 luoxia@supos.com
 * @LastEditTime: 2023-04-04 16:51:51
 * @FilePath: \cloud-console-ssr-frontend\src\layouts\NormalLayout.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { PureComponent } from 'react';

import NewHeader from '../components/NewHeader';

import styles from './normalLayout.module.less';

const NormalLayout = (WrapedComponent) => {
  return class BasicLayout extends PureComponent {
    render() {
      const { props } = this;
      return (
        <div className={styles['basic-layout']}>
          <title></title>
          <NewHeader {...props} />
          <WrapedComponent {...props}></WrapedComponent>
        </div>
      );
    }
  };
};

export default NormalLayout;

import React from 'react';
import { Button } from 'antd';

import { goAuthPage } from '../../utils/helper';

import styles from './index.module.less';

const NoAccess = (props) => {
  let btnText = '';
  let hintText = '';
  const { certificationStatus, style, handleConfirm } = props;

  if (certificationStatus === 1) {
    btnText = '去认证';
    hintText = (
      <div>
        <p className={styles.firstLine}>
          尊敬的用户，您的帐户尚未经过认证，您需要完善身份信息通过认证后，方能继续办理业务。
        </p>
        <p>请您尽快完成身份认证，以便为您提供更好的服务。</p>
      </div>
    );
  }

  const handleClick = () => {
    if (certificationStatus === 1) {
      goAuthPage();
    } else {
      handleConfirm();
    }
  };

  return (
    <div style={style} className={styles['noAccess-content']}>
      <div className={styles.hintBox}>
        <img
          className={styles.icon}
          src="/images/icon_alert.svg"
          alt="warning"
        />

        <p className={styles.hintText}>{hintText}</p>
      </div>

      <Button
        noBorder
        styles={{
          color: '#fff',
          fontWeight: 'normal',
          marginRight: '',
          marginBottom: '50px',
        }}
        radius="big"
        type="primary"
        width="220px"
        onClick={handleClick}
      >
        {btnText}
      </Button>
    </div>
  );
};

export default NoAccess;
